@use './variables.scss' as variables;

.mat-mdc-dialog-container {
  min-width: 329px !important;

  .modal-headline {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: variables.$heading-color;
    text-align: left;
    letter-spacing: normal;
  }

  .mat-mdc-dialog-content {
    mat-form-field {
      width: 100%;
    }
  }

  .mat-dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .mdc-dialog__title {
      padding: 0 20px !important;
    }

    .mat-dialog-header-icon {
      display: flex;
      align-self: center;
      height: 100%;
      padding-right: 24px;

      .mat-mdc-icon-button:hover {
        --mat-mdc-button-persistent-ripple-color: transparent;
      }

      .mat-mdc-icon-button:focus {
        --mat-mdc-button-persistent-ripple-color: transparent;
      }
    }
  }

  .mdc-dialog__content {
    padding: 20px !important;
  }

  mat-dialog-actions {
    display: flex;
    gap: 10px;
    padding: 20px;

    button {
      min-width: 100px !important;
    }
  }
}
