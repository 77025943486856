@use '../variables.scss' as variables;
@use '../themes/eon-theme.scss' as theme;

$size: 32px;
$touch-size: calc($size + 8px);
$touch-size-area: calc($size + 16px);
$mark-size: calc($size / 1.5);
$border-radius: 10px;
$label-size: 18px;
$size-small: 16px;
$touch-size-small: calc($size-small + 8px);
$mark-size-small: calc($size-small / 1.5);
$border-radius-small: 4px;
$label-size-small: 16px;
$turquoise: variables.$eon-turquoise-500;
$red: variables.$eon-red-500;

@mixin checkbox-size(
  $size,
  $markSize,
  $borderRadius,
  $touchSize,
  $touchSizeArea,
  $labelSize
) {
  .mdc-checkbox {
    padding: calc((($size + 8px) - 10px) / 2) !important;
    margin: inherit !important;

    .mdc-checkbox__background {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: $size !important;
      height: $size !important;
      border-radius: $borderRadius !important;
    }

    .mdc-checkbox__checkmark {
      width: $markSize !important;
      height: $markSize !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }

    .mat-mdc-checkbox-ripple {
      width: $touchSize !important;
      height: $touchSize !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }

    .mat-mdc-checkbox-touch-target {
      width: $touchSizeArea !important;
      height: $touchSizeArea !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  label {
    margin-left: -4px !important;
    font-size: $labelSize;
  }
}

.mat-mdc-checkbox {
  @include checkbox-size(
    $size,
    $mark-size,
    $border-radius,
    $touch-size,
    $touch-size-area,
    $label-size
  );
}

.mat-mdc-checkbox.small {
  @include checkbox-size(
    $size-small,
    $mark-size-small,
    $border-radius-small,
    $touch-size-small,
    $touch-size-small,
    $label-size-small
  );
}

.mdc-checkbox__ripple {
  display: none !important;
}

@mixin checkbox-unchecked-hover($colorClass, $color) {
  .mat-mdc-checkbox.#{$colorClass} {
    .mdc-checkbox:hover .mdc-checkbox__background {
      border-color: $color !important;
    }

    .mdc-checkbox:not(:hover) .mdc-checkbox__background {
      border-color: variables.$eon-neutral-600 !important;
    }
  }
}

@include checkbox-unchecked-hover('mat-primary', theme.$active-primary-color);
@include checkbox-unchecked-hover('mat-warn', theme.$active-warn-color);
@include checkbox-unchecked-hover('mat-accent', theme.$active-accent-color);

@mixin checkbox-unchecked-background($colorClass, $color) {
  .mat-mdc-checkbox.#{$colorClass}
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate='true']
    )
    ~ .mdc-checkbox__background {
    background-color: transparent !important;
  }
}

@include checkbox-unchecked-background(
  'mat-primary',
  theme.$active-primary-color
);
@include checkbox-unchecked-background('mat-warn', theme.$active-warn-color);
@include checkbox-unchecked-background(
  'mat-accent',
  theme.$active-accent-color
);

@mixin checkbox-checked-background($colorClass, $color) {
  .mat-mdc-checkbox.#{$colorClass}
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate='true']:enabled
    ~ .mdc-checkbox__background {
    background-color: $color !important;
    border-color: $color !important;
  }
}

@include checkbox-checked-background(
  'mat-primary',
  theme.$active-primary-color
);
@include checkbox-checked-background('mat-warn', theme.$active-warn-color);
@include checkbox-checked-background('mat-accent', theme.$active-accent-color);

@mixin checkbox-outline($colorClass, $color) {
  .mat-mdc-checkbox:focus-within.#{$colorClass}
    > .mdc-form-field
    > .mdc-checkbox {
    .mat-mdc-checkbox-ripple {
      outline: $color solid variables.$btn-outline-width !important;
      border-radius: 0 !important;
    }

    .mdc-checkbox__background {
      border-color: $color !important;
    }
  }
}

@include checkbox-outline('mat-primary', theme.$active-primary-color);
@include checkbox-outline('mat-warn', theme.$active-warn-color);
@include checkbox-outline('mat-accent', theme.$active-accent-color);
