@use '../variables' as variables;

$navbar-border-size: 4px;
$border-color: rgb(229 229 229);

mat-toolbar {
  height: variables.$navbar-height !important;
  border-bottom: $navbar-border-size solid $border-color;
  padding: 0 !important;
  background: #fff !important;
}
