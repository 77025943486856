@use '../variables.scss' as variables;

$font-color: #39393a;
$table-element-height: 44px;

.mat-mdc-table {
  border-spacing: 25px 0 !important;
  border-collapse: separate !important;

  thead {
    .mdc-data-table__header-cell {
      padding: 0;
      font-weight: bold !important;
    }

    .mdc-data-table__header-row {
      height: $table-element-height !important;
    }

    .mat-sort-header {
      border-color: $font-color !important;
      border-bottom-width: 2px !important;
      color: $font-color !important;
    }
  }

  tbody {
    .mdc-data-table__row {
      height: $table-element-height !important;
    }

    .mat-mdc-cell {
      color: $font-color !important;
      padding: 0;
    }
  }
}

.mat-mdc-paginator {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      height: auto !important;
    }

    .mat-mdc-form-field-infix {
      padding-top: 8px !important;
    }
  }
}
