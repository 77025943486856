@use '../variables' as variables;
@use '../themes/eon-theme' as theme;

$box-shadow-color: variables.$eon-neutral-600;
$input-height: 48px;
$infex-top-margin: calc($input-height / 3.8);
$label-top-margin: calc($input-height / 2.28);
$font-size-input: 18px;
$input-height-small: 36px;
$infex-top-margin-small: 14px;
$label-top-margin-small: 8px;
$font-size-input-small: 16px;
$form-field-border-width: 2px;
$form-field-border-width-active: 3px;
$form-field-color: #39393a;
$form-field-border-hover-color: #8f9192;

/* stylelint-disable no-descending-specificity */
.mat-mdc-form-field {
  .mat-mdc-form-field-error-wrapper {
    padding-left: 0 !important;
  }

  &.mat-focused {
    .mdc-notched-outline {
      .mdc-notched-outline__trailing {
        border-width: $form-field-border-width-active !important;
      }

      .mdc-notched-outline__leading {
        border-width: $form-field-border-width-active !important;
      }

      .mdc-notched-outline__notch {
        border-width: $form-field-border-width-active !important;
      }
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-mdc-form-field-hint-wrapper {
      padding-left: 0 !important;
    }

    .mdc-text-field:not(.mdc-text-field--disabled, .mdc-text-field--invalid) {
      .mdc-floating-label {
        color: $form-field-color;
      }
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        height: $input-height;

        .mdc-notched-outline--notched {
          background-color: transparent;
        }

        .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
          top: $label-top-margin;
        }

        .mat-mdc-form-field-infix {
          font-size: $font-size-input;
          padding-top: $infex-top-margin;
        }

        .mdc-notched-outline .mdc-notched-outline__trailing,
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch {
          border-width: $form-field-border-width;
        }

        .mdc-notched-outline__notch {
          border-left: inherit !important;
        }

        .mat-mdc-input-element {
          caret-color: $form-field-color !important;
        }

        mat-label {
          font-size: $font-size-input;
        }
      }
    }

    // :has pseudo class has no support in firefox
    .mdc-text-field--outlined:has(textarea) {
      .mat-mdc-form-field-flex {
        height: auto;
      }
    }

    &.small {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          height: $input-height-small !important;
        }
      }

      .mdc-text-field--outlined:has(textarea) {
        .mat-mdc-form-field-flex {
          height: auto !important;
        }
      }

      .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: $infex-top-margin-small !important;
      }

      .mat-mdc-form-field-infix {
        font-size: $font-size-input-small !important;
        padding-top: $label-top-margin-small !important;
      }

      mat-label {
        font-size: $font-size-input-small;
      }
    }
  }
}

.mat-mdc-form-field:not(
    .mat-form-field-disabled,
    .mat-focused,
    .mat-form-field-invalid
  ) {
  .mat-mdc-text-field-wrapper:hover {
    .mdc-notched-outline {
      .mdc-notched-outline__trailing,
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch {
        border-color: $form-field-border-hover-color !important;
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
