/* You can add global constants here */
$navbar-height: 68px;
$content-size: 1200px;
$footer-gap: 4rem;
$form-content-size: 400px;
$font-family: 'EONBrixSans', arial, geneva, helvetica, sans-serif;
$btn-outline-offset: 3px;
$btn-outline-width: 2px;
$btn-outlined-border-width: 2px;
$min-height-small-btn: 36px;

/* source for color codes https://brand.eon.com/document/256#/design-essentials/colour */

$eon-white: #fff;
$eon-black: #000;
$eon-light-overlay: #f6f6f7;
$eon-red-50: #f7e8da;
$eon-red-100: #f7e8da;
$eon-red-200: #f6c1b0;
$eon-red-300: #f59a86;
$eon-red-400: #f05b48;
$eon-red-500: #ea1b0a;
$eon-red-600: #e01000;
$eon-red-700: #c90a01;
$eon-red-800: #b00402;
$eon-red-900: #76020d;
$mat-eon-red: (
  50: $eon-red-50,
  100: $eon-red-100,
  200: $eon-red-200,
  300: $eon-red-300,
  400: $eon-red-400,
  500: $eon-red-500,
  600: $eon-red-600,
  700: $eon-red-700,
  800: $eon-red-800,
  900: $eon-red-900,
  contrast: (
    50: $eon-black,
    100: $eon-black,
    200: $eon-black,
    300: $eon-black,
    400: $eon-white,
    500: $eon-white,
    600: $eon-white,
    700: $eon-white,
    800: $eon-white,
    900: $eon-white,
  ),
);
$eon-neutral-50: #fff;
$eon-neutral-100: #fff;
$eon-neutral-200: #fcfbfa;
$eon-neutral-300: #f9f6f4;
$eon-neutral-400: #e6e3e1;
$eon-neutral-500: #cccbca;
$eon-neutral-600: #999;
$eon-neutral-700: #666;
$eon-neutral-800: #262626;
$eon-neutral-900: #000;
$mat-eon-neutral: (
  50: $eon-neutral-50,
  100: $eon-neutral-100,
  200: $eon-neutral-200,
  300: $eon-neutral-300,
  400: $eon-neutral-400,
  500: $eon-neutral-500,
  600: $eon-neutral-600,
  700: $eon-neutral-700,
  800: $eon-neutral-800,
  900: $eon-neutral-900,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: $eon-white,
    700: $eon-white,
    800: $eon-white,
    900: $eon-white,
  ),
);
$eon-turquoise-50: #e1eded;
$eon-turquoise-100: #e1eded;
$eon-turquoise-200: #b0dade;
$eon-turquoise-300: #7fc7cf;
$eon-turquoise-400: #4fb5c0;
$eon-turquoise-500: #1da1af;
$eon-turquoise-600: #178a9d;
$eon-turquoise-700: #0f738a;
$eon-turquoise-800: #144d62;
$eon-turquoise-900: #182739;
$mat-eon-turquoise: (
  50: $eon-turquoise-50,
  100: $eon-turquoise-100,
  200: $eon-turquoise-200,
  300: $eon-turquoise-300,
  400: $eon-turquoise-400,
  500: $eon-turquoise-500,
  600: $eon-turquoise-600,
  700: $eon-turquoise-700,
  800: $eon-turquoise-800,
  900: $eon-turquoise-900,
  contrast: (
    50: $eon-black,
    100: $eon-black,
    200: $eon-black,
    300: $eon-black,
    400: $eon-white,
    500: $eon-white,
    600: $eon-white,
    700: $eon-white,
    800: $eon-white,
    900: $eon-white,
  ),
);
