@use 'sass:map';
@use '@angular/material' as mat;
@use '../themes/eon-theme.scss' as theme;
@use '../variables.scss' as variables;

$min-height-small-btn: variables.$min-height-small-btn;
$turquoise: variables.$eon-turquoise-500;
$turquoise-light: variables.$eon-turquoise-200;
$red: variables.$eon-red-500;
$red-light: variables.$eon-red-200;

button {
  &.mdc-button {
    letter-spacing: normal !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    min-height: 48px;
    border-radius: 10px !important;
    box-sizing: border-box !important;

    .mdc-button__label {
      margin: 0 5px;
    }
  }

  &.small {
    min-height: $min-height-small-btn;

    .mat-mdc-button-touch-target {
      height: $min-height-small-btn;
    }
  }

  &.mat-unthemed:not(.mat-primary, .mat-accent, .mat-warn):focus {
    .mat-mdc-button-touch-target {
      outline-offset: variables.$btn-outline-offset !important;
      outline: theme.$active-primary-default-contrast solid
        variables.$btn-outline-width !important;
    }
  }

  @mixin theme-colors($colorClass, $color) {
    &.mdc-button--outlined {
      &.#{$colorClass} {
        border-color: $color !important;
      }
    }
    &.#{$colorClass}:focus {
      .mat-mdc-button-touch-target {
        outline-offset: variables.$btn-outline-offset;
        outline: $color solid variables.$btn-outline-width !important;
      }
    }
  }

  @include theme-colors('mat-primary', theme.$active-primary-color);
  @include theme-colors('mat-accent', theme.$active-accent-color);
  @include theme-colors('mat-warn', theme.$active-warn-color);
}

:disabled {
  @mixin disabled-colors($colorClass, $primary-color, $primary-contrast) {
    &.#{$colorClass} {
      &.mdc-button--outlined {
        color: $primary-color !important;
        background: transparent !important;
        border-color: $primary-color !important;
      }

      &.mdc-button--unelevated,
      &.mdc-button--raised {
        color: $primary-contrast !important;
        background: $primary-color !important;
      }

      color: $primary-color !important;
      background: transparent !important;
    }
  }

  @include disabled-colors(
    'mat-primary',
    theme.$active-primary-color,
    theme.$active-primary-default-contrast
  );
  @include disabled-colors(
    'mat-accent',
    theme.$active-accent-color,
    theme.$active-accent-default-contrast
  );
  @include disabled-colors(
    'mat-warn',
    theme.$active-warn-color,
    theme.$active-warn-default-contrast
  );

  opacity: 0.5;
}
