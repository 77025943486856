@use 'sass:map';
@use '@angular/material' as mat;
@use '../themes/eon-theme.scss' as theme;
@use '../variables.scss' as variables;

$radio-size: 32px;
$radio-size-outer: calc($radio-size + 8px);
$radio-size-small: 24px;
$radio-size-outer-small: calc($radio-size-small + 8px);

@mixin set-size($radioSize, $radioOuterSize) {
  .mdc-form-field {
    .mdc-radio {
      padding: calc(
        (var(--mdc-radio-state-layer-size, $radio-size-outer) - $radio-size) / 2
      ) !important;
      width: $radioSize !important;
      height: $radioSize !important;

      .mdc-radio__background {
        width: $radioSize !important;
        height: $radioSize !important;
      }

      .mdc-radio__native-control {
        width: $radioOuterSize !important;
        height: $radioOuterSize !important;
      }

      .mat-mdc-radio-touch-target {
        position: absolute;
        top: 50%;
        height: $radioOuterSize;
        left: 50%;
        width: $radioOuterSize;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.mat-mdc-radio-button {
  --mat-mdc-radio-ripple-color: inherit !important;
  --mat-mdc-radio-checked-ripple-color: inherit !important;
}

.mat-mdc-radio-button:not(.small) {
  @include set-size($radio-size, $radio-size-outer);
}

.mat-mdc-radio-button.small {
  @include set-size($radio-size-small, $radio-size-outer-small);
}

.mat-mdc-radio-button .mdc-form-field > label {
  cursor: pointer;
}

.mat-mdc-radio-button
  .mdc-radio:not(:hover)
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: variables.$eon-neutral-600 !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: transparent !important;
}

@mixin outer-circle-hover($colorClass, $color) {
  .mat-mdc-radio-button.#{$colorClass}
    .mdc-radio:hover
    .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: $color !important;
  }
}

@include outer-circle-hover('mat-primary', theme.$active-primary-color);
@include outer-circle-hover('mat-warn', theme.$active-warn-color);
@include outer-circle-hover('mat-accent', theme.$active-accent-color);

@mixin outer-circle($colorClass, $color) {
  .mat-mdc-radio-button.#{$colorClass}
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: $color !important;
  }
}

@include outer-circle('mat-primary', theme.$active-primary-color);
@include outer-circle('mat-warn', theme.$active-warn-color);
@include outer-circle('mat-accent', theme.$active-accent-color);

@mixin inner-circle($colorClass, $color) {
  .mat-mdc-radio-button.#{$colorClass}
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    background-color: $color !important;
    border-width: inherit !important;
  }
}

@include inner-circle('mat-primary', theme.$active-primary-color);
@include inner-circle('mat-warn', theme.$active-warn-color);
@include inner-circle('mat-accent', theme.$active-accent-color);

@mixin radio-outline($colorClass, $color) {
  .mat-mdc-radio-button:focus-within.#{$colorClass}
    > .mdc-form-field
    > .mdc-radio
    > .mdc-radio__background {
    outline-offset: variables.$btn-outline-offset !important;
    outline: $color solid variables.$btn-outline-width !important;
  }
}

@include radio-outline('mat-primary', theme.$active-primary-color);
@include radio-outline('mat-warn', theme.$active-warn-color);
@include radio-outline('mat-accent', theme.$active-accent-color);
