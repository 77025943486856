@use './variables.scss' as variables;
@use './angular-material-customization/base.scss';
@use './colors.scss';

$spot-information-menu-width: variables.$spot-information-menu-width;
$spot-information-fix-width: variables.$spot-information-fix-width;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: variables.$eon-neutral-300;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: inherit !important;
}

.w-100 {
  width: 100%;
}

.charging-speed-slider-value-indicator {
  .mdc-slider__value-indicator-container {
    width: max-content !important;
  }
}

.mat-mdc-option {
  &.mdc-list-item--selected {
    .mdc-list-item__primary-text {
      color: variables.$eon-turquoise-500 !important;
    }
  }
}

.map-toolbar-body {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.maplibregl-popup {
  z-index: 10;
  font: variables.$font-family;

  .project-location-marker-group {
    .project-location-marker-group-headline {
      margin: 0;
      font-weight: bold !important;

      span {
        font-weight: normal;
      }
    }
  }
}

.spots-found-list {
  overflow-y: auto;
  max-height: 350px;

  &.mdc-list {
    padding: 0 !important;

    .mdc-list-item {
      padding: 0 !important;
    }

    .mdc-list-item:focus::before {
      background-color: unset;
    }
  }

  .mdc-list-item--selected {
    .mdc-list-item__content {
      background-color: rgb(229 229 229) !important;

      .mdc-list-item__primary-text {
        color: rgb(0 0 0 / 87%) !important;
      }
    }
  }
}

.mdc-text-field:not(.mdc-text-field--disabled, .mdc-text-field--invalid) {
  .mdc-floating-label {
    color: #8f9192 !important;
  }
}

.spot-information {
  &__header--capitalized {
    text-transform: capitalize;
  }

  &--fixed-width {
    .mat-mdc-tab-group {
      &.spot-information__tab {
        width: 90%;

        .mat-mdc-tab-body-wrapper {
          width: $spot-information-fix-width;
        }
      }
    }

    .mat-mdc-tab-group:not(.spot-information__tab) {
      width: $spot-information-fix-width;

      .mat-mdc-tab-body-wrapper {
        width: $spot-information-fix-width;
      }
    }
  }

  &--full-width {
    /* stylelint-disable-next-line no-descending-specificity */
    .mat-mdc-tab-group {
      &.spot-information__tab {
        width: 90%;

        .mat-mdc-tab-body-wrapper {
          width: calc(100% + $spot-information-menu-width);
        }
      }
    }

    .mat-mdc-tab-group:not(.spot-information__tab) {
      width: 100%;

      .mat-mdc-tab-body-wrapper {
        width: 100%;
      }
    }
  }
}

.attribute-chips {
  .mdc-evolution-chip-set__chips {
    display: flex;
    justify-content: flex-end;
  }
}

.location-popup {
  z-index: 3;

  .mdc-tab-new-value-indicator::after {
    content: '';

    position: absolute;
    top: 20px;
    right: -10px;

    width: 8px;
    height: 8px;

    background-color: variables.$eon-red-500;
    border-radius: 50%;
  }
}

.priority-3 {
  z-index: 3;
}

.priority-2 {
  z-index: 2;
}

/**
 * workaround to have a top-center-position - caution this disables the top-left-position
 * for any mgl-control
 */
.maplibregl-ctrl-top-left:first-of-type {
  left: 50%;
  transform: translateX(-50%);
}

.link-button {
  .mat-mdc-button-persistent-ripple::before {
    display: none;
  }
}

.location-table {
  .mat-mdc-header-cell {
    min-width: 150px;
  }
}
