@use '../variables.scss' as variables;
@use '../themes/eon-theme.scss' as theme;

$border-radius: 20px;
$border-color: #8f9192;
$border-width: 2px;

/* stylelint-disable no-descending-specificity */
.mat-mdc-slide-toggle {
  .mdc-switch__track,
  .mdc-switch__icons {
    display: none !important;
  }

  .mdc-switch {
    border: $border-width solid $border-color;
    border-radius: $border-radius !important;
    height: 30px !important;
    width: 50px !important;

    .mdc-switch__ripple {
      display: none;
    }

    &.mdc-switch--selected {
      .mdc-switch__handle-track {
        margin-left: -3px;
      }
    }

    &.mdc-switch--unselected {
      .mdc-switch__handle-track {
        .mdc-switch__handle {
          margin-left: 3px;
        }
      }
    }

    &.small {
      .mdc-switch__handle {
        width: 17px !important;
        height: 17px !important;
      }

      .mdc-switch {
        max-height: 25px !important;
        max-width: 40px !important;

        &.mdc-switch--selected {
          .mdc-switch__handle-track {
            margin-left: 0;
          }
        }
      }
    }
  }

  @mixin slide-toggle-theme-colors($colorClass, $color) {
    &.#{$colorClass} {
      .mdc-switch:hover {
        border: $border-width solid $color !important;
      }

      .mdc-switch--selected {
        &.mdc-switch--checked {
          border: $border-width solid $color !important;
        }
      }

      .mdc-switch--selected:enabled:hover:not(:focus, :active) {
        .mdc-switch__handle::after {
          background: $color !important;
        }
      }

      .mdc-switch--unselected:enabled:hover:not(:focus, :active) {
        .mdc-switch__handle::after {
          background: $border-color !important;
        }
      }

      .mdc-switch--unselected:enabled:active {
        .mdc-switch__handle::after {
          background: $border-color !important;
        }
      }

      .mdc-switch--unselected:enabled:focus:not(:active) {
        .mdc-switch__handle::after {
          background: $border-color !important;
        }
      }

      .mdc-switch--selected:enabled:active {
        .mdc-switch__handle::after {
          background: $color !important;
        }
      }

      .mdc-switch--selected:enabled:focus:not(:active) {
        .mdc-switch__handle::after {
          background: $color !important;
        }
      }
    }
  }

  @include slide-toggle-theme-colors(
    'mat-primary',
    theme.$active-primary-color
  );
  @include slide-toggle-theme-colors('mat-warn', theme.$active-warn-color);
  @include slide-toggle-theme-colors('mat-accent', theme.$active-accent-color);
}
/* stylelint-enable no-descending-specificity */
