.country-panel {
  display: flex;
  justify-content: center;
  padding: 10px !important;

  .mdc-list-item__primary-text {
    width: 100%;
  }

  .mat-pseudo-checkbox {
    display: none;
  }
}
