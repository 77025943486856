/**
* this file contains overwrites due to two reasons.
* the first is the respective component is not yet customized (in angular-customization repo),
* or the customization needs to be overwritten.
*/

@use './dialog.scss';
@use './select.scss';

.snackbar {
  position: relative;
  bottom: 60px;
  width: 550px !important;
  max-width: 100% !important;
}

button {
  &.mdc-icon-button {
    &.mdc-icon-button:focus {
      .mat-mdc-button-touch-target {
        outline: unset !important;
        outline-offset: unset !important;
      }
    }

    &.mat-unthemed:not(.mat-primary, .mat-accent, .mat-warn):focus {
      .mat-mdc-button-touch-target {
        outline: unset !important;
        outline-offset: unset !important;
      }
    }
  }

  &.mdc-icon-button:hover {
    background-color: transparent !important;
  }
}

.mat-mdc-icon-button:hover {
  --mat-mdc-button-persistent-ripple-color: transparent;
}

.mat-mdc-icon-button:focus {
  --mat-mdc-button-persistent-ripple-color: transparent;
}

.mat-mdc-form-field-error-wrapper {
  padding-left: 0 !important;
}
